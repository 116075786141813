import { FormClose, FormEdit, Save } from 'grommet-icons'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../theme'
let ReactQuill
if (typeof window !== 'undefined') {
  ReactQuill = require('react-quill')
}

const Wrapper = styled.div`
`

const ActionButton = styled.span`
  padding-top: 5px;
  cursor: pointer;
`

const EditableHTML = ({ children, onSave }) => {
  const [editing, setEditing] = useState(false)
  const [value, setValue] = useState(children)

  const onSaveAction = async () => {
    try {
      await onSave(value)
      setEditing(false)
    } catch (err) {
      setValue(children)
    }
  }

  const onCancelAction = () => {
    setEditing(false)
    setValue(children)
  }

  if (editing) return <Wrapper>
    <ReactQuill theme="snow" value={value} onChange={setValue}/>
    <ActionButton onClick={onSaveAction}><Save color={colors.green} /></ActionButton>
    <ActionButton onClick={onCancelAction}><FormClose /></ActionButton>
  </Wrapper>

  return <Wrapper>
    <ActionButton onClick={() => setEditing(true)}><FormEdit /></ActionButton>  
    <div dangerouslySetInnerHTML={{ __html: value }} />
  </Wrapper>
}

EditableHTML.propTypes = {
  children: PropTypes.node,
  onSave: PropTypes.func
}

EditableHTML.defaultProps = {
  onSave: (value) => {}
}

export default EditableHTML
