import { useApolloClient } from '@apollo/client'
import { useEffect, useState } from 'react'
import { createContainer } from 'unstated-next'
import NotificationState from '../../Notifications/index.state'
import GET_SERIE from './getSerie.graphql'
import UPDATE_SERIE from './updateLessonSerie.graphql'

const useLessonSerieState = (initialState) => {
  const [serie, setSerie] = useState()
  const client = useApolloClient()
  const { notifySuccess } = NotificationState.useContainer()

  useEffect(() => {
    fetch()
    // eslint-disable-next-line
  }, [])

  const fetch = async () => {
    const { data } = await client.query({
      query: GET_SERIE,
      variables: { _id: initialState.serieId },
      fetchPolicy: 'network-only'
    })
    setSerie(data.lessonV2Serie)
  }

  const updateSerie = (payload) => {
    setSerie({ ...serie, ...payload })
  }

  const moveEpisodeAtIndexRight = (i) => {
    let episodes = [...serie.lessons]
    if (i === episodes.length - 1) return
    const episode = {...episodes[i]}
    episodes[i] = {...episodes[i + 1]}
    episodes[i + 1] = episode
    setSerie({ ...serie, lessons: episodes.map((e, i) => ({ ...e, index: i })) })
  }

  const moveEpisodeAtIndexLeft = (i) => {
    let episodes = [...serie.lessons]
    if (i === 0) return
    const episode = {...episodes[i]}
    episodes[i] = {...episodes[i - 1]}
    episodes[i - 1] = episode
    setSerie({ ...serie, lessons: episodes.map((e, i) => ({ ...e, index: i })) })
  }

  const save = async () => {
    let input = { ...serie }
    delete input._id
    input.episodes = input.lessons.filter(d => d).map((lesson, i) => {
      return {
        lessonId: lesson._id,
        index: i
      }
    })
    delete input.lessons
    const { data } = await client.mutate({
      mutation: UPDATE_SERIE,
      variables: {
        _id: serie._id,
        input
      }
    })
    if (data && data.updateLessonV2Serie && data.updateLessonV2Serie._id) {
      notifySuccess({ title: 'Saved', text: 'Serie saved!' })
    }
  }

  const updateTagAtIndex = (i, value) => {
    let newTags = [...(serie.tags || [])]
    newTags[i] = value
    updateSerie({ tags: newTags })
  }
  
  const addTag = (value) => {
    let newTags = [...(serie.tags || []), value]
    updateSerie({ tags: newTags })
  }

  const removeTagAtIndex = (i) => {
    let newTags = [...(serie.tags || [])]
    newTags.splice(i, 1)
    updateSerie({ tags: newTags })
  }

  const updateSubject = (subject) => {
    if (subject) {
      updateSerie({ subjects: [subject] })
    } else {
      updateSerie({ subjects: [] })
    }
  }

  return { serie, updateSerie, moveEpisodeAtIndexRight, moveEpisodeAtIndexLeft, save, updateTagAtIndex, addTag, removeTagAtIndex, updateSubject }
}

const LessonSerieState = createContainer(useLessonSerieState)

export default LessonSerieState
