import { navigate } from 'gatsby'
import { CheckBox, Select, TextInput } from 'grommet'
import { FormNext, FormPrevious, FormTrash, Save } from 'grommet-icons'
import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../theme'
import ImageUploader from '../../ImageUploaderOld'
import Button from '../../UI/Button'
import EditableHTML from '../../UI/EditableHTML'
import EditableText from '../../UI/EditableText'
import Countries from './components/Countries'
import LessonSerieState from './index.state'
import AgeRestriction from './components/AgeRestriction'

const SUBJECTS = [
  { value: '', label: 'Select a subject' },
  { value: 'STORY_WRITING', label: "Story Writing" },
  { value: 'WRITING_STYLES', label: "Writing Styles" },
  { value: 'VOCABULARY', label: "Vocabulary" },
  { value: 'SPELLING_AND_PUNCTUATION', label: "Spelling & Punctuation" },
  { value: 'READING_COMPREHENSION', label: "Reading Comprehension" },
  { value: 'GENERAL', label: "General" },
]

const Wrapper = styled.div`
  > :nth-child(3) {
    margin-bottom: 20px;
  }
`

const Title = styled.div`
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 15px;
  font-family: 'Robotto', sans-serif;
`

const Background = styled.div`
  height: 100%;
  width: 100%;
  background-image: url("${props => props.src || 'https://cdn.nightzookeeper.com/edu-assets/images/image-not-found.jpg'}");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 20px;
`

const Lessons = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 15px;
`

const Lesson = styled.div`
  position: relative;
  background-color: #000;
  color: #fff;
  border-radius: 8px;
  padding-top: 20px;
  > :nth-child(2) {
    cursor: pointer;
    height: 200px;
    width: 100%;
    background-image: url("${props => props.backgroundUrl || 'https://cdn.nightzookeeper.com/edu-assets/images/image-not-found.jpg'}");
    background-size: cover;
    background-position: center;
  }
  > :nth-child(3) {
    text-align: center;
    padding: 10px 0;
    border-radius: 0 0 8px 8px
  }
`

const SectionTitle = styled.div`
  font-size: 24px;
  line-height: 24px;
  margin-top: 20px;
  margin-bottom: 15px;
  font-family: 'Robotto', sans-serif;
`

const Actions = styled.div`
  height: 40px;
  background-color: #000;
  > * > * {
    cursor: pointer;
  }
`

const SaveButton = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 90px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #67AB5C;
  border-radius: 8px;
  cursor: pointer;
`

const ComingSoon = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-top: 20px;
  > :nth-child(2) {
    width: 30px;
    margin-left: 20px;
  }
  margin-top: 10px;
`

const Description = styled.div`
  margin-bottom: 20px;
  background-color: #fff;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 2px 3px rgba(0,0,0,0.3);
`

const Tags = styled.div`
  margin-bottom: 20px;
  display: flex;
  padding-right: 10px;
  > * {
    margin-right: 10px;
    margin-bottom: 10px;
  }
`

const Subject = styled.div`
  margin-bottom: 20px;
  display: flex;
  padding-right: 10px;
  > * {
    margin-right: 10px;
    margin-bottom: 10px;
    color: black;
  }
`

const Tag = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.indigo};
  padding: 4px 8px;
  color: #fff;
`

const NewTag = styled.div`

`

const Thumbnails = styled.div`
  display: flex;
  > :nth-child(1) {
    height: ${400 * 1.5}px;
    width: ${220 * 1.5}px;
    margin-right: 30px;
    > :first-child { height: 100%; width: 100%; }
  }
  > :nth-child(2) {
    height: ${160 * 1.5}px;
    width: ${220 * 1.5}px;
    > :first-child { height: 100%; width: 100%; }
  }
`

const LessonSerie = (props) => {
  const { serie, updateSerie, moveEpisodeAtIndexRight, moveEpisodeAtIndexLeft, save, updateTagAtIndex, addTag, removeTagAtIndex, updateSubject} = LessonSerieState.useContainer()

  const onSubjectChange = (ev) => {
    updateSubject(ev.target.value)
  }

  if (!serie) return null
  return <Wrapper>
    <Title>
      <EditableText onSave={name => updateSerie({ name })}>
        {serie.name}
      </EditableText>
    </Title>
    <Description>
      <EditableHTML onSave={description => updateSerie({ description })}>
        { serie.description || 'No Description' }
      </EditableHTML>
    </Description>
    <Countries />
    <AgeRestriction />
    <ComingSoon>
      { /* eslint-disable-next-line */ }
      <label>Coming soon</label>
      <CheckBox checked={serie.comingSoon} onChange={e => updateSerie({ comingSoon: e.target.checked })} />
    </ComingSoon>
    <SectionTitle>Tags</SectionTitle>
    <Tags>
      {
        (serie.tags || []).map((t, i) => <Tag key={i}>
          <EditableText onSave={value => updateTagAtIndex(i, value)}>{t}</EditableText>
          <FormTrash color='#f00' onClick={() => removeTagAtIndex(i)} />
        </Tag>)
      }
      <NewTag>
        <TextInput onKeyPress={e => {
          if (e.which === 13) {
            addTag(e.target.value)
            e.target.value = ''
          }
        }} />
      </NewTag>
    </Tags>
    <SectionTitle>Subject</SectionTitle>
    <Subject>
      <select value={(serie.subjects && serie.subjects[0]) || ''} onChange={onSubjectChange}>
        {SUBJECTS.map((subject, i) => <option key={i} value={subject.value}>{subject.label}</option>)}
      </select>
    </Subject>
    <Thumbnails>
      <div>
      {
        serie.portraitThumbnailUrl
          ? <Background src={serie.portraitThumbnailUrl}>
              <Button onClick={() => updateSerie({ portraitThumbnailUrl: null })}>Edit</Button>
            </Background>
          : <ImageUploader
            onComplete={url => updateSerie({ portraitThumbnailUrl: url })}
          />
      }
      </div>
      <div>
      {
        serie.backgroundUrl
          ? <Background src={serie.backgroundUrl}>
              <Button onClick={() => updateSerie({ backgroundUrl: null })}>Edit</Button>
            </Background>
          : <ImageUploader
            onComplete={url => updateSerie({ backgroundUrl: url })}
          />
      }
      </div>
    </Thumbnails>
    <SectionTitle>Episodes</SectionTitle>
    <Lessons>
      { serie.lessons.filter(d => d).map((l, i) => <Lesson key={l._id} backgroundUrl={l.backgroundUrl}>
        <Actions>
          <div>
            <FormPrevious color='#fff' onClick={() => moveEpisodeAtIndexLeft(i)} />
            <FormNext color='#fff' onClick={() => moveEpisodeAtIndexRight(i)}  />
          </div>
        </Actions>
        { /* eslint-disable-next-line */ }
        <div onClick={() => navigate(`/lesson/${l._id}`)} />
        <div>
          {l.title}
        </div>
      </Lesson>)}
    </Lessons>
    <SaveButton onClick={save}>
      <Save color='#fff' />
    </SaveButton>
  </Wrapper>
}

LessonSerie.propTypes = {

}

LessonSerie.defaultProps = {

}

export default ({ serieId }) => <LessonSerieState.Provider initialState={{ serieId }}>
  <LessonSerie />
</LessonSerieState.Provider>
