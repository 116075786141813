import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../../theme'
import LessonSerieState from '../index.state'

const Wrapper = styled.div`
  > :first-child {
    font-weight: bold;
    margin-bottom: 6px;
  }
  > :nth-child(2) {
    display: flex;
    flex-wrap: wrap;
    > * { margin-right: 10px; }
    > :last-child { margin-right: 0; }
  }
`

const Age = styled.div`
  height: 30px;
  width: 45px;
  background-color: ${colors.indigo};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  filter: ${props => props.blocked ? `grayscale(0)` : `grayscale(1)`};
  cursor: pointer;
  user-select: none;
`

const AGES = [
  { yearGroup: 0, value: '5-6' },
  { yearGroup: 1, value: '6-7' },
  { yearGroup: 2, value: '7-8' },
  { yearGroup: 3, value: '8-9' },
  { yearGroup: 4, value: '9-10' },
  { yearGroup: 5, value: '10-11' },
  { yearGroup: 6, value: '11-12' },
  { yearGroup: 7, value: '12+' },
]

const Countries = (props) => {
  const {
    serie,
    updateSerie,
  } = LessonSerieState.useContainer()

  const blockedForYearGroups = serie.blockedForYearGroups || []

  const toggleBlockedAge = (yearGroup) => {
    let newBlockedForYearGroups = [...blockedForYearGroups]
    let index = newBlockedForYearGroups.indexOf(yearGroup)
    if (index >= 0) {
      newBlockedForYearGroups.splice(newBlockedForYearGroups.indexOf(yearGroup), 1)
      updateSerie({ blockedForYearGroups: newBlockedForYearGroups })
    } else {
      updateSerie({ blockedForYearGroups: [...newBlockedForYearGroups, yearGroup] })
    }
  }

  return <Wrapper>
    <div>Blocked for ages:</div>
    <div>
      { AGES.map((age) => <Age key={age.yearGroup} blocked={(blockedForYearGroups || []).indexOf(age.yearGroup) >= 0} onClick={() => toggleBlockedAge(age.yearGroup)}>{age.value}</Age>)}
    </div>
  </Wrapper>
}

Countries.propTypes = {

}

Countries.defaultProps = {

}

export default Countries
