import { PageProps } from 'gatsby';
import React from "react"
import LessonSerie from "../../components/Lessons/LessonSerie";

const LessonSeriePage = (props: PageProps) => {
  if (typeof window === "undefined") return null

  return <LessonSerie serieId={props.params.serieId} />
}

export default LessonSeriePage
