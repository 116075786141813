import React from 'react'
import styled from 'styled-components'
import LessonSerieState from '../index.state'

const Wrapper = styled.div`
  > :first-child {
    font-weight: bold;
    margin-bottom: 6px;
  }
  > :nth-child(2) {
    display: flex;
    flex-wrap: wrap;
    > * { margin-right: 10px; }
    > :last-child { margin-right: 0; }
  }
`

const Flag = styled.div`
  height: 30px;
  width: 45px;
  background-image: url("https://cdn.nightzookeeper.com/edu-assets/images/flags/${props => props.code}.png");
  background-position: center;
  background-size: contain;
  filter: ${props => props.blocked ? `grayscale(0)` : `grayscale(1)`};
`

const COUNTRIES = ['GB', 'US', 'CN', 'JP']

const Countries = (props) => {
  const {
    serie,
    updateSerie,
  } = LessonSerieState.useContainer()

  const blockedCountries = serie.blockedCountries || []

  const toggleBlockCountry = (code) => {
    let newBlockedCountries = [...blockedCountries]
    let index = newBlockedCountries.indexOf(code)
    if (index >= 0) {
      newBlockedCountries.splice(newBlockedCountries.indexOf(code), 1)
      updateSerie({ blockedCountries: newBlockedCountries })
    } else {
      updateSerie({ blockedCountries: [...newBlockedCountries, code] })
    }
  }

  return <Wrapper>
    <div>Blocked in:</div>
    <div>
      { COUNTRIES.map((c) => <Flag key={c} code={c} blocked={blockedCountries.indexOf(c) >= 0} onClick={() => toggleBlockCountry(c)} />)}
    </div>
  </Wrapper>
}

Countries.propTypes = {

}

Countries.defaultProps = {

}

export default Countries
